<template>
  <metainfo>
    <template #title="{ content }">{{ content ?? 'Interactive Studios' }}</template>
  </metainfo>
  <router-view v-if="!streamHasErrored" />
  <ErrorDisplay v-else />
  <CookieBanner v-if="showCookiebanner" />
</template>

<script>
import { defineAsyncComponent } from 'vue';
const CookieBanner = defineAsyncComponent(() => import('@/components/CookieBanner'));
const ErrorDisplay = defineAsyncComponent(() => import('@/components/ErrorDisplay'));
import updateIframeHeight from '@/assets/js/utils/update-iframe-height';

export default {
  data() {
    return {
      app: null,
      resizeObserver: null,
    };
  },

  components: {
    CookieBanner,
    ErrorDisplay,
  },
  computed: {
    showCookiebanner() {
      const routes = ['Stream', 'LiveStream', 'LiveM365', 'LiveWindows'];

      return (
        this.$store.state.retailer.cookie_banner_enabled &&
        !this.$store.state.retailer.cookie_opt_out &&
        !this.$store.state.display.loadingMedia &&
        !this.streamHasErrored &&
        routes.includes(this.$route.name)
      );
    },

    streamHasErrored() {
      return this.$store.state.display.error.hasErrored;
    },
  },

  mounted() {
    this.app = document.querySelector('#app');
    this.resizeObserver = new ResizeObserver(() => {
      updateIframeHeight();
    });

    this.resizeObserver.observe(this.app);
  },

  beforeUnmount() {
    this.resizeObserver.unobserve(this.app);
  },
};
</script>
